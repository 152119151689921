import React, { useState } from "react";
import styled from "styled-components";
import BuildingAddInfoForm from "../components/BuildingAddInfoForm/BuildingAddInfoForm";
import SideDrawer from "../components/SideDrawer/SideDrawer";
import SourceCard from "../components/SourceCard/SourceCard";

const PageContainer = styled.div`
  width: 100%;
`;
const BuildingInfo = styled.div`
  font-size: 42px;
  font-weight: 200;
  margin-top: 50px;
  margin-left: 80px;
  margin-right: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1030px;
  @media screen and (max-width: 480px) {
    font-size: 32px;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 25px;
    gap: 110px;
  }
`;
const BuildingNumber = styled.div`
  color: #6a6a6a;
`;

const BuildingCode = styled.div`
  color: #000;
`;
const Line = styled.div`
  margin-top: 2px;
  height: 0px;
  border: 0.5px solid #000000;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
`;
const Citation = styled.text`
  font-size: 16px;
  color: #fff;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 200px;

  font-style: normal;
  font-weight: 200;
  font-size: 34px;

  margin-top: 15px;
  margin-left: 80px;
  margin-right: 80px;

  @media screen and (max-width: 480px) {
    gap: 20px;
    margin-left: 30px;
    margin-right: 30px;
    flex-direction: column;
    font-size: 26px;
  }
`;
const LangDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const ARLangDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26.5px;
`;
const DetailsContainer = styled.div`
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 350px;
  margin-left: 80px;
  margin-right: 80px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;
const DatapointContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
const Label = styled.div`
  font-style: normal;
  font-weight: 200;
  font-size: 17px;
  color: #6a6a6a;
  text-transform: uppercase;
`;

const Datapoint = styled.div`
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  color: #000000;
`;
const AddInfoContainer = styled.div`
  border: 2px dashed #000000;
  border-radius: 3px;
  box-sizing: border-box;
  height: 117px;

  margin-top: 50px;
  margin-left: 80px;
  margin-right: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 330px;
    height: 97px;
    margin-left: 22px;
    margin-right: 22px;
    margin-top: 22px;
    margin-bottom: 95px;
    gap: 6px;
  }
`;
const AddInfoEn = styled.div`
  font-weight: 200;
  color: #6a6a6a;
  font-size: 34px;
  margin-top: 40px;
  margin-left: 420px;
  @media screen and (max-width: 480px) {
    font-size: 21px;
    margin-left: 45px;
    margin-top: 38px;
  }
`;

const SourcesContainer = styled.div`
  margin-top: 55px;
  margin-left: 80px;
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-weight: 200;
  font-size: 16px;
  color: #787878;
  @media screen and (max-width: 480px) {
    margin-left: 22px;
    margin-top: 40px;
    margin-right: 22px;
    font-size: 12px;
  }
`;
const AddInfoAr = styled.div`
  font-weight: 300;
  font-size: 36px;
  color: #6a6a6a;
  text-align: right;
  margin-top: 37px;
  margin-right: 420px;
  @media screen and (max-width: 480px) {
    font-size: 22px;
    margin-top: 36px;
    margin-right: 18px;
  }
`;
const BackArrowContainer = styled.div`
  cursor: pointer;
  margin-left: 80px;
  margin-top: 70px;
  color: #6a6a6a;
  font-size: 16px;
  font-weight: 200;
  display: flex;
  gap: 5px;
  @media screen and (max-width: 480px) {
    gap: 4px;
    font-size: 22px;
    margin-top: 85px;
    margin-left: 30px;
  }
`;
const BackText = styled.div`
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

export default function InnerBuilding() {
  const [addInfoDrawer, setAddInfoDrawer] = useState(false);
  return (
    <PageContainer>
      <BackArrowContainer>
        <BackText>← Public Administrative Buildings</BackText>
        <BackText style={{ textAlign: "right" }} lang="ar">
          المباني الإدارية العامة
        </BackText>
      </BackArrowContainer>
      <BuildingInfo>
        <BuildingNumber>#93</BuildingNumber>
        <BuildingCode>WEILI-03</BuildingCode>
      </BuildingInfo>
      <Line />
      <TitleContainer>
        <div>Ministry of Electricity and Energy</div>
        <div style={{ textAlign: "right" }} lang="ar">
          وزارة الكهرباء و الطاقة المتجددة
        </div>
      </TitleContainer>
      <DetailsContainer>
        <LangDetailsContainer>
          <DatapointContainer>
            <Label>3D Model</Label>
            <Datapoint> NA</Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Label>Location</Label>
            <Datapoint>
              {" "}
              8 Ramses, El Qobba Bridge, El Weili, Cairo Governorate{" "}
              <Citation>01</Citation>
            </Datapoint>
          </DatapointContainer>
        </LangDetailsContainer>{" "}
        <ARLangDetailsContainer style={{ textAlign: "right" }} lang="ar">
          <DatapointContainer>
            <Label>نموذج ثلاثي الأبعاد</Label>
            <Datapoint> تحميل النموذج</Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Label>الموقع</Label>
            <Datapoint>
              {" "}
              ٨ رمسيس، كوبري القبة، الوايلي، محافظة القاهرة
            </Datapoint>
          </DatapointContainer>
        </ARLangDetailsContainer>{" "}
      </DetailsContainer>
      <SourcesContainer>
        <div style={{ marginLeft: "10px" }}>Showing 1 out of 1 sources</div>
        <SourceCard></SourceCard>
      </SourcesContainer>
      <AddInfoContainer
        onClick={() => {
          setAddInfoDrawer(true);
        }}
      >
        <AddInfoEn> Add info—</AddInfoEn>
        <AddInfoAr lang="ar">إضافة معلومات</AddInfoAr>
      </AddInfoContainer>
      <SideDrawer
        Component={BuildingAddInfoForm}
        isOpen={addInfoDrawer}
        setIsOpen={setAddInfoDrawer}
        eng_title="Add info"
        ar_title="إضافة معلومات"
      ></SideDrawer>
    </PageContainer>
  );
}
