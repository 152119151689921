import React, { useState } from "react";
import styled from "styled-components";

const NavbarContainer = styled.div`
  padding-top: 28px;
  display: flex;
  margin-left: 80px;
  margin-right: 80px;
  flex-direction: row;
  justify-content: space-between;
  background: #bdc1be;
  width: 100%;
`;

const NavBarItem = styled.span`
  font-size: 16px;
`;

const Button = styled.button`
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  display: flex;
  gap: 5px;
`;

export default function Navbar() {
  const [selectedPage, setSelectedPage] = useState("dataland");
  return (
    <NavbarContainer>
      <Button
        onClick={() => {
          setSelectedPage("dataland");
        }}
        style={{
          color: selectedPage === "dataland" ? "white" : "black",
        }}
      >
        <NavBarItem>DATALAND</NavBarItem>
        <NavBarItem lang="ar">داتالاند</NavBarItem>
      </Button>
      <Button
        onClick={() => {
          setSelectedPage("about");
        }}
        style={{
          color: selectedPage === "about" ? "white" : "black",
        }}
      >
        <NavBarItem>ABOUT</NavBarItem>
        <NavBarItem lang="ar">عن المشروع</NavBarItem>
      </Button>
      <Button
        onClick={() => {
          setSelectedPage("building_inventory");
        }}
        style={{
          color: selectedPage === "building_inventory" ? "white" : "black",
        }}
      >
        <NavBarItem>BUILDING INVENTORY</NavBarItem>
        <NavBarItem lang="ar">قائمة المباني</NavBarItem>
      </Button>
      <Button
        onClick={() => {
          setSelectedPage("precedents_inventory");
        }}
        style={{
          color: selectedPage === "precedents_inventory" ? "white" : "black",
        }}
      >
        <NavBarItem>PRECEDENTS INVENTORY</NavBarItem>
        <NavBarItem lang="ar">قائمة المجلات</NavBarItem>
      </Button>
      <Button
        onClick={() => {
          setSelectedPage("merchandise");
        }}
        style={{
          color: selectedPage === "merchandise" ? "white" : "black",
        }}
      >
        <NavBarItem>MERCHANDISE</NavBarItem>
        <NavBarItem lang="ar">داتالاند</NavBarItem>
      </Button>
      <Button
        onClick={() => {
          setSelectedPage("login");
        }}
        style={{
          color: selectedPage === "login" ? "white" : "black",
        }}
      >
        <NavBarItem>LOGIN</NavBarItem>
        <NavBarItem lang="ar">تسجيل الدخول</NavBarItem>
      </Button>
    </NavbarContainer>
  );
}
