import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";

const NItemsSelected = styled.span`
  font-size: 17px;
  color: #434343;
`;

const SelectOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  color: #434343;
  font-family: "TWK Lausanne";
`;

export default function Dropdown({
  filterBy,
  setFilterBy,
  placeholder,
  options,
}) {
  const handleTypeSelect = (e) => {
    setFilterBy(e);
  };

  const colorStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#DBDBD",
        border: "0.5px solid #000000;",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "#DBDBDB"
          : isSelected
          ? "#FFB399"
          : "white",
        color: "black",
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "16px",
        fontWeight: "300",
      };
    },
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...props}>
        <div>
          <NItemsSelected>{`${nbValues} items selected`}</NItemsSelected>
        </div>
      </components.ValueContainer>
    );
  };

  const formatOptionLabel = ({ label_eng, label_ar }) => (
    <SelectOptionContainer>
      <span>{label_eng}</span>
      <span lang="ar">{label_ar}</span>
    </SelectOptionContainer>
  );

  return (
    <div style={{ width: "300px" }}>
      <Select
        placeholder={placeholder}
        options={options}
        onChange={handleTypeSelect}
        isMulti
        styles={colorStyles}
        hideSelectedOptions={false}
        components={{ ValueContainer }}
        formatOptionLabel={formatOptionLabel}
        isSearchable={false}
        value={filterBy}
      />
    </div>
  );
}
