import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
  gap: 40px;
  margin-top: 20px;
`;

const Label = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  display: flex;
  gap: 8px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CheckboxLabel = styled.div`
  font-size: 18px;
  display: flex;
  gap: 8px;
`;

export default function SortBy({ sortBy, setSortBy }) {
  let handleCheckBoxClick = (e, label_eng, label_ar) => {
    if (e.target.checked) {
      setSortBy({
        label_eng: label_eng,
        label_ar: label_ar,
        value: e.target.value,
      });
    } else {
      setSortBy(null);
    }
  };
  return (
    <Container>
      <div>
        <Label>
          <span>AVAILABILITY OF</span>
          <span lang="ar">توافر</span>
        </Label>
        <CheckboxContainer>
          <Checkbox
            checked={sortBy?.value === "issues" ? true : false}
            value="issues"
            onClick={(e) => {
              handleCheckBoxClick(e, "Issues", "إصدارات");
            }}
          >
            <CheckboxLabel>
              <span>Issues</span>
              <span lang="ar">إصدارات</span>
            </CheckboxLabel>
          </Checkbox>
          <Checkbox
            style={{ marginLeft: "0px" }}
            checked={sortBy?.value === "covers" ? true : false}
            value={"covers"}
            onClick={(e) => {
              handleCheckBoxClick(e, "Covers", "الأغلفة");
            }}
          >
            <CheckboxLabel>
              <span>Covers</span>
              <span lang="ar">الأغلفة</span>
            </CheckboxLabel>
          </Checkbox>
        </CheckboxContainer>
      </div>
      <div>
        <Label>
          <span>ORDER</span>
          <span>ترتيب</span>
        </Label>
        <CheckboxContainer>
          <Checkbox
            checked={sortBy?.value === "alphabetically" ? true : false}
            value={"alphabetically"}
            label_eng="Alphabetically"
            label_ar="أبجديا"
            onClick={(e) => {
              handleCheckBoxClick(e, "Alphabetically", "أبجديا");
            }}
          >
            <CheckboxLabel>
              <span>Alphabetically</span>
              <span>أبجديا</span>
            </CheckboxLabel>
          </Checkbox>
          <Checkbox
            style={{ marginLeft: "0px" }}
            checked={sortBy?.value === "chronologically" ? true : false}
            value={"chronologically"}
            label_eng="Chronologically"
            label_ar="زمنيا"
            onClick={(e) => {
              handleCheckBoxClick(e, "Chronologically", "زمنيا");
            }}
          >
            <CheckboxLabel>
              <span>Chronologically</span>
              <span>زمنيا</span>
            </CheckboxLabel>
          </Checkbox>
        </CheckboxContainer>
      </div>
    </Container>
  );
}
