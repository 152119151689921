import React, { useState } from "react";
import Heading from "./Heading";
import FilterByButton from "./FilterByButton";
import SortByButton from "./SortByButton";
import FilterBy from "./FilterBy";
import SortBy from "./SortBy";
import Searchbar from "../Searchbar/Searchbar";
import FilterChip from "../Chip/FilterChip";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  background: #bdc1be;
  margin-top: 60px;
`;

const FilterContainer = styled.div`
  margin-top: 65px;
  display: flex;
`;

const FilterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 480px) {
    gap: 50px;
  }
  width: 50%;
`;

const HR = styled.hr`
  border: 0.5px solid #000000;
`;

const ChipsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export default function Filter() {
  const [buttonSelected, setButtonSelected] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [dates, setDates] = useState([]);
  const [architects, setArchitects] = useState([]);
  const [isSearchSelected, setIsSearchSelected] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  let handleFilterByClick = () => {
    if (buttonSelected === "filterBy") {
      setButtonSelected(null);
    } else {
      setButtonSelected("filterBy");
    }
  };

  let handleSortByClick = () => {
    if (buttonSelected === "sortBy") {
      setButtonSelected(null);
    } else {
      setButtonSelected("sortBy");
    }
  };
  return (
    <Container>
      <Heading />
      <FilterContainer>
        <FilterButtonsContainer
          style={{
            display: isSearchSelected ? "none" : null,
            width: isMobile ? " 70%" : null,
          }}
        >
          <FilterByButton
            enabled={buttonSelected === "filterBy" ? true : false}
            handleFilterByClick={handleFilterByClick}
          />
          <SortByButton
            enabled={buttonSelected === "sortBy" ? true : false}
            handleSortByClick={handleSortByClick}
          />
        </FilterButtonsContainer>
        <FilterButtonsContainer
          style={
            isSearchSelected
              ? { width: "100%" }
              : isMobile
              ? { width: "30%" }
              : null
          }
        >
          <div style={{ width: "100%" }}>
            <Searchbar
              setIsSearchSelected={setIsSearchSelected}
              placeholder_ar={"البحث عن مجلة"}
              placeholder_eng={"Search for a magazine"}
            />
          </div>
        </FilterButtonsContainer>
      </FilterContainer>
      <HR />
      <div style={{ display: buttonSelected !== "filterBy" ? "none" : null }}>
        <FilterBy
          districts={districts}
          setDistricts={setDistricts}
          dates={dates}
          setDates={setDates}
          architects={architects}
          setArchitects={setArchitects}
        />
      </div>
      <div style={{ display: buttonSelected !== "sortBy" ? "none" : null }}>
        <SortBy sortBy={sortBy} setSortBy={setSortBy} />
      </div>
      <ChipsContainer>
        {sortBy ? (
          <FilterChip
            chipTitle={"Sort by"}
            label_eng={sortBy.label_eng}
            label_ar={sortBy.label_ar}
            setSortBy={setSortBy}
            value={sortBy.value}
          />
        ) : null}
        {districts.map((item) => (
          <FilterChip
            chipTitle={"Filter by"}
            label_eng={item.label_eng}
            label_ar={item.label_ar}
            setFilterBy={setDistricts}
            filterBy={districts}
            value={item.value}
          />
        ))}
        {dates.map((item) => (
          <FilterChip
            chipTitle={"Filter by"}
            label_eng={item.label_eng}
            label_ar={item.label_ar}
            setFilterBy={setDates}
            filterBy={dates}
            value={item.value}
          />
        ))}
        {architects.map((item) => (
          <FilterChip
            chipTitle={"Filter by"}
            label_eng={item.label_eng}
            label_ar={item.label_ar}
            setFilterBy={setArchitects}
            filterBy={architects}
            value={item.value}
          />
        ))}
      </ChipsContainer>
    </Container>
  );
}
