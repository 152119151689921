import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const IssueButton = styled.button`
  background-color: transparent;
  font-size: 18px;
  border: 0px;
  cursor: pointer;
`;

const IssueContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 23px;
`;

const Header = styled.div`
  font-size: 36px;
`;

const Subheading = styled.div`
  font-size: 16px;
  color: #434343;
  margin-top: 15px;
`;

const IssueMobileContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 23px;
`;

const IssueButtonMobile = styled.button`
  background-color: transparent;
  font-size: 18px;
  border: 0px;
  cursor: pointer;
  display: flex;
  gap: 5px;
`;

export default function Heading() {
  const [issue, setIssue] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <HeadingContainer>
      <div>
        {isMobile ? (
          <IssueMobileContainer>
            <IssueButtonMobile
              onClick={() => {
                setIssue(null);
              }}
              style={{ color: issue === null ? "white" : "black" }}
            >
              <span>ALL</span>
              <span lang="ar">الكل</span>
            </IssueButtonMobile>
            <IssueButtonMobile style={{ cursor: "none" }}>
              <span>ISSUE</span>
              <span lang="ar">عدد</span>
            </IssueButtonMobile>
            <IssueButtonMobile
              onClick={() => {
                setIssue(1);
              }}
              style={{ color: issue === 1 ? "white" : "black" }}
            >
              1
            </IssueButtonMobile>
            <IssueButtonMobile
              onClick={() => {
                setIssue(2);
              }}
              style={{ color: issue === 2 ? "white" : "black" }}
            >
              2
            </IssueButtonMobile>
            <IssueButtonMobile
              onClick={() => {
                setIssue(3);
              }}
              style={{ color: issue === 3 ? "white" : "black" }}
            >
              3
            </IssueButtonMobile>
          </IssueMobileContainer>
        ) : (
          <IssueContainer>
            <IssueButton
              onClick={() => {
                setIssue(null);
              }}
              style={{ color: issue === null ? "white" : "black" }}
            >
              ALL
            </IssueButton>
            <IssueButton
              onClick={() => {
                setIssue(1);
              }}
              style={{ color: issue === 1 ? "white" : "black" }}
            >
              ISSUE 1
            </IssueButton>
            <IssueButton
              onClick={() => {
                setIssue(2);
              }}
              style={{ color: issue === 2 ? "white" : "black" }}
            >
              ISSUE 2
            </IssueButton>
            <IssueButton
              onClick={() => {
                setIssue(3);
              }}
              style={{ color: issue === 3 ? "white" : "black" }}
            >
              ISSUE 3
            </IssueButton>
          </IssueContainer>
        )}

        <Header>PUBLIC ADMINISTRATIVE BUILDINGS</Header>
        <Subheading>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lobortis
          massa in sollicitudin mattis. Vestibulum vitae ultricies velit, vel
          tincidunt sapien. Nunc enim libero, tincidunt sapien. Nunc enim{" "}
        </Subheading>
      </div>
      <div lang="ar" style={{ textAlign: "right" }}>
        {isMobile ? null : (
          <IssueContainer
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IssueButton
              onClick={() => {
                setIssue(3);
              }}
              style={{ color: issue === 3 ? "white" : "black" }}
            >
              عدد ٣
            </IssueButton>
            <IssueButton
              onClick={() => {
                setIssue(2);
              }}
              style={{ color: issue === 2 ? "white" : "black" }}
            >
              عدد ٢
            </IssueButton>
            <IssueButton
              onClick={() => {
                setIssue(1);
              }}
              style={{ color: issue === 1 ? "white" : "black" }}
            >
              عدد ١
            </IssueButton>
            <IssueButton
              onClick={() => {
                setIssue(null);
              }}
              style={{ color: issue === null ? "white" : "black" }}
            >
              الكل
            </IssueButton>
          </IssueContainer>
        )}
        <Header>المباني الإدارية العامة</Header>
        <Subheading>
          لوريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى)
          ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال
          المعيار للنص{" "}
        </Subheading>
      </div>
    </HeadingContainer>
  );
}
