import React from "react";
import styled from "styled-components";
import { districts_data } from "../../constants/districts";
import AsyncSelect from "react-select/async";
import { useMediaQuery } from "react-responsive";

const SelectOptionContainer = styled.div`
  display: flex;
  font-size: 17px;
  color: #434343;
  gap: 10px;
`;

export default function Searchbar({
  setIsSearchSelected,
  placeholder_eng = null,
  placeholder_ar = null,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const handleBuildingSelect = (e) => {};

  const colorStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: "#bdc1be",
      border: 0,
      boxShadow: "none",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        background: isFocused ? "#DBDBDB" : "#bdc1be",
        borderRadius: isFocused ? "25px" : "0px",
        color: "black",
        padding: "20px",
      };
    },
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      background: "#bdc1be",
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "24px",
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      border: "none",
      boxShadow: "none",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: isMobile ? "18px" : "24px",
      };
    },
  };

  const formatOptionLabel = ({ label_eng, label_ar }) => (
    <SelectOptionContainer>
      <span>{label_eng}</span>
      <span lang="ar">{label_ar}</span>
    </SelectOptionContainer>
  );

  const placeholder = isMobile ? (
    "Search"
  ) : (
    <div>
      <span style={{ marginRight: "10px" }}>{placeholder_eng}</span>
      <span lang="ar">{placeholder_ar}</span>
    </div>
  );

  const loadOptions = (inputValue) => {
    return new Promise((resolve) => resolve(districts_data));
  };
  return (
    <div>
      <AsyncSelect
        placeholder={placeholder}
        onChange={handleBuildingSelect}
        styles={colorStyles}
        hideSelectedOptions={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => null,
        }}
        formatOptionLabel={formatOptionLabel}
        isSearchable={true}
        loadOptions={loadOptions}
        onFocus={() => {
          setIsSearchSelected(true);
        }}
        onBlur={() => {
          setIsSearchSelected(false);
        }}
        isClearable={true}
        openMenuOnClick={false}
      />
    </div>
  );
}
