import React from "react";
import styled from "styled-components";
import IGLogo from "../assets/ig_logo.png";

const ComingSoonContainer = styled.div`
  position: absolute;
  @media screen and (max-width: 480px) {
    display: none;
  }
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ComingSoonContainerMobile = styled.div`
  position: absolute;
  top: 380px;
  @media screen and (min-width: 480px) {
    display: none;
  }
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const DatalandSvgWeb = styled.img`
  @media screen and (max-width: 480px) {
    display: none;
  }
  width: 100%;
  position: relative;
`;

const DatalandSvgMobile = styled.img`
  @media screen and (min-width: 480px) {
    display: none;
  }
  width: 100%;
  position: relative;
`;

const IGContainer = styled.a`
  display: flex;
  gap: 10px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
`;

const Container = styled.div`
  width: 100%;
  justify-content: center;
  margin: 20px;
  @media screen and (max-width: 480px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  position: relative;
`;

const Description = styled.div`
  font-size: 18px;
`;

function ComingSoon() {
  return (
    <Container style={{ textAlign: "center" }}>
      <div>
        <DatalandSvgWeb
          src={
            require("../assets/Dataland_English_Horizontal Logo Lockup_coming-soon-web.svg")
              .default
          }
          alt="dataland"
        />
        <DatalandSvgMobile
          src={
            require("../assets/Dataland_English_Horizontal Logo Lockup_coming-soon-mobile.svg")
              .default
          }
          alt="dataland"
        />
        <ComingSoonContainer>
          <img
            src={require("../assets/element-coming-soon-web.svg").default}
            alt="comingsoon"
          />
        </ComingSoonContainer>
        <ComingSoonContainerMobile>
          <img
            src={require("../assets/element-coming-soon-mobile.svg").default}
            alt="comingsoon"
          />
        </ComingSoonContainerMobile>
      </div>
      <IGContainer
        target="_blank"
        href="https://instagram.com/datalandpublications?igshid=Mzc1MmZhNjY="
      >
        <img src={IGLogo} alt="ig logo" />
        <Description>@DATALANDPUBLICATIONS</Description>
      </IGContainer>
    </Container>
  );
}

export default ComingSoon;
