import React from "react";
import { Drawer, Space, ConfigProvider } from "antd";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { RxCross1 } from "react-icons/rx";

const TitleContainer = styled.div`
  border-bottom: 0.5px solid #000000;
  padding: 17px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 22px;
  display: flex;
  gap: 5px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

function SideDrawer({
  Component = null,
  isOpen,
  setIsOpen,
  eng_title = null,
  ar_title = null,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <Drawer
      title={
        <Title>
          <span>{eng_title}</span>
          <span lang="ar">{ar_title}</span>
        </Title>
      }
      closable={false}
      placement={"right"}
      width={isMobile ? "100%" : "50%"}
      open={isOpen}
      extra={
        <Space>
          <CloseButton
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <RxCross1 />
          </CloseButton>
        </Space>
      }
      style={{ backgroundColor: "#bdc1be", fontFamily: "ABC Favorit Arabic" }}
      bodyStyle={{ padding: "0px" }}
    >
      <Component />
    </Drawer>
  );
}

export default SideDrawer;
