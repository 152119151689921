import React from "react";
import Select from "../FilterSelect/Select";
import { districts_data } from "../../constants/districts";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
  gap: 40px;
  margin-top: 20px;
`;

const FilterLabel = styled.div`
  margin-bottom: 5px;
  font-size: 19px;
  display: flex;
  gap: 5px;
`;

export default function FilterBy({
  districts,
  setDistricts,
  dates,
  setDates,
  architects,
  setArchitects,
}) {
  return (
    <Container>
      <div>
        <FilterLabel>
          <span>District</span>
          <span lang="ar">منطقة</span>
        </FilterLabel>
        <Select
          filterBy={districts}
          setFilterBy={setDistricts}
          options={districts_data}
          placeholder={"Choose district"}
        />
      </div>
      <div>
        <FilterLabel>
          <span>Date of Construction</span>
          <span lang="ar">تاريخ البناء</span>
        </FilterLabel>
        <Select
          filterBy={dates}
          setFilterBy={setDates}
          options={districts_data}
          placeholder={"Choose date"}
        />
      </div>
      <div>
        <FilterLabel>
          <span>Architect</span>
          <span lang="ar">المعماري</span>
        </FilterLabel>
        <Select
          filterBy={architects}
          setFilterBy={setArchitects}
          options={districts_data}
          placeholder={"Choose architect"}
        />
      </div>
    </Container>
  );
}
