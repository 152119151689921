import React from "react";
import styled from "styled-components";

const Input = styled.input`
  border: 0.5px solid #000000;
  border-radius: 3px;
  height: 48px;
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  outline: none;
`;

const Textbox = React.forwardRef(({ placeholder = "text", ...rest }, ref) => {
  return <Input placeholder={placeholder} {...rest} ref={ref} />;
});

export default Textbox;
