import React from "react";
import styled from "styled-components";
import { building_title_of_info_verified } from "../../constants/building_title_of_info_verified";
import Select from "../Select/Select";
import Textbox from "../Textbox/Textbox";

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const InputContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const InputLabel = styled.label`
  font-size: 18px;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
`;

const LargeTextbox = styled.textarea`
  width: 100%;
  border: 0.5px solid #000000;
  border-radius: 3px;
  background-color: transparent;
  outline: none;
  padding: 10px;
  font-family: "TWK Lausanne";
  font-size: 16px;
  cursor: default;
  box-sizing: border-box;
`;

const Container = styled.div`
  margin-bottom: 10px;
`;
function InfoEntry() {
  return (
    <Container>
      <InputRow>
        <InputContainer>
          <InputLabel>
            <span>Page number</span>
            <span lang="ar">رقم الصفحة</span>
          </InputLabel>
          <Textbox placeholder={"Write issuing date"} />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            <span>Title of info</span>
            <span lang="ar">عنوان المعلومة</span>
          </InputLabel>
          <Select
            options={building_title_of_info_verified}
            placeholder={"Choose issuing date format"}
          />
        </InputContainer>
      </InputRow>
      <div style={{ marginTop: "15px" }}>
        <InputLabel>
          <span>General description</span>
          <span lang="ar">وصف عام</span>
        </InputLabel>
        <LargeTextbox
          placeholder="Character limit is 50"
          rows="10"
          maxLength="50"
        />
      </div>
    </Container>
  );
}

export default InfoEntry;
