import React from "react";
import { GrDown } from "react-icons/gr";
import { BiSortAlt2 } from "react-icons/bi";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { RxCross1 } from "react-icons/rx";

const FilterButton = styled.button`
  font-size: 24px;
  background-color: transparent;
  border: 0px;
  vertical-align: center;
  cursor: pointer;
`;

export default function SortByButton({ enabled = true, handleSortByClick }) {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <FilterButton
      style={{
        color: enabled ? null : "#6a6a6a",
        fontSize: isMobile ? "18px" : null,
      }}
      onClick={() => {
        handleSortByClick();
      }}
    >
      <BiSortAlt2 style={{ fontSize: "20px" }} /> {"   "}
      Sort by {"   "}
      {enabled ? (
        <RxCross1 style={{ fontSize: "15px" }} />
      ) : (
        <GrDown style={{ fontSize: "15px" }} />
      )}
    </FilterButton>
  );
}
