import React, { useState } from "react";
import styled from "styled-components";
import { BsPlusLg } from "react-icons/bs";
import { HiMinus } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";

const SourceCodeContainer = styled.div`
  margin-left: 15px;
  font-weight: 200;
  font-size: 22px;
  color: #ffffff;
  @media (max-width: 480px) {
    margin-left: 15px;
    margin-top: 20px;
    font-size: 18px;
  }
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  gap: 27px;
  box-sizing: border-box;
  border: 0.5px solid #000000;
  border-radius: 3px;
  border-radius: 3px;
  display: flex;
  height: 60px;
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 12px;
    height: fit-content;
    align-items: flex-start;
    width: 331px;
  }
`;
const VerifiedContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  background: #ffffff;
  align-items: center;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  @media (max-width: 480px) {
    position: absolute;
    top: 20px;
    left: 45px;
    border-radius: 9px;
  }
`;
const Verified = styled.div`
  color: #000000;
  font-size: 12px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-weight: 200;
  font-size: 22px;
  color: #000000;
  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 15px;
    margin-left: 15px;
    font-size: 20px;
    gap: 8px;
    text-align: center;
  }
`;
const SourceContainer = styled.div`
  margin-left: 350px;
  font-weight: 200;
  font-size: 16px;
  color: #000000;
  @media (max-width: 480px) {
    margin-left: 15px;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
  }
`;
const ExpandButton = styled.button`
  margin-left: 230px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #000000;
  @media (max-width: 480px) {
    position: absolute;
    top: 20px;
    right: 15px;
    margin-left: 0px;
  }
`;
const SourceTypeContainer = styled.div`
  position: absolute;
  top: -9px;
  left: 65px;
  background-color: #bdc1be;
  font-weight: 200;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  color: #6a6a6a;
  padding-left: 4px;
  padding-right: 4px;
  @media (max-width: 480px) {
    left: 10px;
  }
`;

export default function SourceCard() {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <CardContainer>
      <SourceCodeContainer>01</SourceCodeContainer>
      <TitleContainer>
        <div>Alam Al Bena’a</div>{" "}
        <div
          style={{ textAlign: isMobile ? "center" : "right", color: "#434343" }}
          lang="ar"
        >
          عالم البناء
        </div>
      </TitleContainer>
      <VerifiedContainer>
        <Verified>Verified</Verified>
        <Verified style={{ textAlign: "right" }} lang="ar">
          تم التحقق منها
        </Verified>
      </VerifiedContainer>
      <SourceContainer>Issue #36 — 1983</SourceContainer>
      <ExpandButton onClick={() => setExpanded(!expanded)}>
        {expanded ? <HiMinus size={28} /> : <BsPlusLg size={21} />}
      </ExpandButton>
      <SourceTypeContainer>
        <div>Architect, date of construction</div>
        <div
          style={{
            textAlign: "right",
            fontSize: "13px",
            color: "#434343",
          }}
          lang="ar"
        >
          المعماري ، تاريخ البناء
        </div>
      </SourceTypeContainer>
    </CardContainer>
  );
}
