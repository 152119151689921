import React from "react";
import styled from "styled-components";
import LogoImg from "./assets/footer_logo.svg";
import IGLogo from "./assets/ig_logo.png";

const FooterContainer = styled.footer`
  padding: 5%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 30px;
  }
  width: 100%;
  background: #bdc1be;
`;

const Column = styled.div`
  width: 33.33%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  width: 60%;
  height: auto;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Link = styled.a`
  font-size: 18px;
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const Description = styled.div`
  font-size: 18px;
`;

const IGContainer = styled.a`
  display: flex;
  gap: 10px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`;

export default function Footer() {
  return (
    <FooterContainer>
      <Column>
        <Logo src={LogoImg} alt="logo" />
      </Column>
      <Column>
        <Menu>
          <Link>
            <span>DATALAND</span>
            <span lang="ar">داتالاند</span>
          </Link>
          <Link>
            <span>ABOUT</span>
            <span lang="ar">عن المشروع</span>
          </Link>
          <Link>
            <span>BUILDINGS INVENTORY</span>
            <span lang="ar">قائمة المباني</span>
          </Link>
          <Link>
            <span>PRECEDENTS INVENTORY</span>
            <span lang="ar">قائمة المجلات</span>
          </Link>
          <Link>
            <span>SHOP</span>
            <span lang="ar">تسوق</span>
          </Link>
        </Menu>
      </Column>
      <Column>
        <Menu>
          <Description>
            A print magazine and digital platform focusing on Cairo’s built
            environment
          </Description>
          <Description lang="ar" style={{ textAlign: "right" }}>
            مجلة مطبوعة ومنصة رقمية تركز على البيئة العمرانية في القاهرة
          </Description>
          <IGContainer target="_blank" href="https://www.instagram.com">
            <img src={IGLogo} alt="ig logo" />
            <Description>@datalandpublishing</Description>
          </IGContainer>
        </Menu>
      </Column>
    </FooterContainer>
  );
}
