import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import countryEnum from "../../enums/countryEnum";
import Countries from "../../enums/countryEnum";

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
  line-height: 30px;
`;

const IssueButton = styled.button`
  background-color: transparent;
  font-size: 18px;
  border: 0px;
  cursor: pointer;
`;

const IssueContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 23px;
`;

const Header = styled.div`
  font-size: 36px;
`;

const Subheading = styled.div`
  font-size: 16px;
  color: #434343;
  margin-top: 15px;
`;

const CountryFilterMobileContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 23px;
`;

const CountryButtonMobile = styled.button`
  background-color: transparent;
  font-size: 18px;
  border: 0px;
  cursor: pointer;
  display: flex;
  gap: 5px;
`;

export default function Heading() {
  const [country, setCountry] = useState(countryEnum.EGYPT);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <HeadingContainer>
      <div>
        {isMobile ? (
          <CountryFilterMobileContainer>
            <CountryButtonMobile
              onClick={() => {
                setCountry(countryEnum.EGYPT);
              }}
              style={{ color: country === Countries.EGYPT ? "white" : "black" }}
            >
              <span>EGYPT</span>
              <span lang="ar">مصر</span>
            </CountryButtonMobile>
            <CountryButtonMobile
              onClick={() => {
                setCountry(countryEnum.MENA_REGION);
              }}
              style={{
                color: country === Countries.MENA_REGION ? "white" : "black",
              }}
            >
              <span>MENA REGION</span>
              <span lang="ar">الشرق الأوسط</span>
            </CountryButtonMobile>
            <CountryButtonMobile
              onClick={() => {
                setCountry(countryEnum.LEBANON);
              }}
              style={{
                color: country === Countries.LEBANON ? "white" : "black",
              }}
            >
              <span>LEBANON</span>
              <span lang="ar">لبنان</span>
            </CountryButtonMobile>
            <CountryButtonMobile
              onClick={() => {
                setCountry(countryEnum.KSA);
              }}
              style={{
                color: country === Countries.KSA ? "white" : "black",
              }}
            >
              <span>KSA</span>
              <span lang="ar">السعودية</span>
            </CountryButtonMobile>
          </CountryFilterMobileContainer>
        ) : (
          <IssueContainer>
            <IssueButton
              onClick={() => {
                setCountry(Countries.EGYPT);
              }}
              style={{ color: country === Countries.EGYPT ? "white" : "black" }}
            >
              EGYPT
            </IssueButton>
            <IssueButton
              onClick={() => {
                setCountry(Countries.MENA_REGION);
              }}
              style={{
                color: country === Countries.MENA_REGION ? "white" : "black",
              }}
            >
              MENA REGION
            </IssueButton>
            <IssueButton
              onClick={() => {
                setCountry(Countries.LEBANON);
              }}
              style={{
                color: country === Countries.LEBANON ? "white" : "black",
              }}
            >
              LEBANON
            </IssueButton>
            <IssueButton
              onClick={() => {
                setCountry(Countries.KSA);
              }}
              style={{ color: country === Countries.KSA ? "white" : "black" }}
            >
              KSA
            </IssueButton>
          </IssueContainer>
        )}

        <Header>PRECEDENTS INVENTORY</Header>
        <Subheading>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lobortis
          massa in sollicitudin mattis. Vestibulum vitae ultricies velit, vel
          tincidunt sapien. Nunc enim libero, tincidunt sapien. Nunc enim{" "}
        </Subheading>
      </div>
      <div lang="ar" style={{ textAlign: "right" }}>
        {isMobile ? null : (
          <IssueContainer
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IssueButton
              onClick={() => {
                setCountry(Countries.KSA);
              }}
              style={{
                color: country === Countries.KSA ? "white" : "black",
              }}
            >
              السعودية
            </IssueButton>
            <IssueButton
              onClick={() => {
                setCountry(Countries.LEBANON);
              }}
              style={{
                color: country === Countries.LEBANON ? "white" : "black",
              }}
            >
              لبنان
            </IssueButton>
            <IssueButton
              onClick={() => {
                setCountry(Countries.MENA_REGION);
              }}
              style={{
                color: country === Countries.MENA_REGION ? "white" : "black",
              }}
            >
              الشرق الأوسط
            </IssueButton>
            <IssueButton
              onClick={() => {
                setCountry(Countries.EGYPT);
              }}
              style={{ color: country === Countries.EGYPT ? "white" : "black" }}
            >
              مصر
            </IssueButton>
          </IssueContainer>
        )}
        <Header lang="ar">قائمة المجلات</Header>
        <Subheading lang="ar">
          لوريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى)
          ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال
          المعيار للنص
        </Subheading>
      </div>
    </HeadingContainer>
  );
}
