import Footer from "./components/Footer/Footer";
import BurgerMenu from "./components/BurgerMenu/BurgerMenu";
import "./index.scss";
import BuildingInventory from "./pages/BuildingInventory";
import { useMediaQuery } from "react-responsive";
import Navbar from "./components/Navbar/Navbar";
import InnerBuilding from "./pages/InnerBuilding";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrecedentInventory from "./pages/PrecedentInventory";
import ComingSoon from "./pages/ComingSoon";

function App() {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isComingSoon = true;

  if (isComingSoon) {
    return (
      <div className="app-container">
        <ComingSoon />
      </div>
    );
  }

  return (
    <div className="app-container">
      <BrowserRouter>
        {isMobile ? <BurgerMenu /> : <Navbar />}
        <Routes>
          <Route path="/building-inventory" element={<BuildingInventory />} />
          <Route path="/building-inventory/:id" element={<InnerBuilding />} />
          <Route path="/precedent-inventory" element={<PrecedentInventory />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
