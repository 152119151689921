import React from "react";
import Select from "../FilterSelect/Select";
import { districts_data } from "../../constants/districts";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
  margin-top: 20px;
`;

const FilterLabel = styled.div`
  margin-bottom: 10px;
  font-size: 19px;
  display: flex;
  gap: 5px;
`;

const PublishingDuration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const FromToContainer = styled.div`
  font-size: 16px;
  display: flex;
  gap: 6px;
  margin-top: auto;
  margin-bottom: auto;
`;

const ApplyButton = styled.button`
  background: #ffb399;
  border-radius: 3px;
  height: 50px;
  width: 100px;
  margin-top: auto;
`;

export default function FilterBy({}) {
  return (
    <Container>
      <div>
        <FilterLabel>
          <div>Publishing Duration</div>
          <div>مدة النشر</div>
        </FilterLabel>
        <PublishingDuration>
          <FromToContainer>
            <div>From</div>
            <div lang="ar">من</div>
          </FromToContainer>
          <div>
            <Select options={districts_data} placeholder={"Choose year"} />
          </div>
          <FromToContainer>
            <div>To</div>
            <div lang="ar">إلي</div>
          </FromToContainer>
          <div>
            <Select options={districts_data} placeholder={"Choose year"} />
          </div>
        </PublishingDuration>
      </div>
      <div>
        <FilterLabel>
          <div>Issuing Frequency</div>
          <div>تردد الإصدار</div>
        </FilterLabel>
        <Select options={districts_data} placeholder={"Choose year"} />
      </div>
      <ApplyButton>
        <span>Apply</span>
        <span>-</span>
        <span lang="ar">تطبيق</span>
      </ApplyButton>
    </Container>
  );
}
