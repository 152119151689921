import React, { useState } from "react";
import Filter from "../components/PrecedentFilter/Filter";
import styled from "styled-components";
import MagazineCard from "../components/MagazineCard/MagazineCard";
import { GoPrimitiveDot } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import AddMagazine from "../components/AddMagazine/AddMagazine";

const CardsContainer = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PaginationContainer = styled.div`
  padding-left: 80px;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
  }
  color: #787878;
  font-size: 16px;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 140px;
`;
const ShowMoreButton = styled.button`
  background: #bdc1be;
  border: 0.5px solid #6a6a6a;
  border-radius: 22px;
  font-size: 16px;
  width: 580px;
  padding: 10px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;

const NoteContainer = styled.div`
  padding-left: 80px;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
  }
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
`;

const Dot = styled.div`
  font-size: 30px;
  color: #ffb399;
`;
const Note = styled.div`
  color: #787878;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AddMagazineButtonContainer = styled.div`
  margin-bottom: 45px;
  margin-left: 80px;
  margin-right: 80px;
  @media screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
    width: 90%;
  }
  box-sizing: border-box;
  overflow: auto;
  width: 50%;
  border-bottom: 1px solid #000000;
  padding-bottom: 10px;
  display: flex;
  font-size: 22px;
`;
const AddMagazineButton = styled.div`
  display: flex;
  gap: 15px;
  cursor: pointer;
`;

const AddMagazineFormContainer = styled.div`
  margin-left: 80px;
  margin-right: 80px;
  width: 70%;
  @media screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
  }
`;

export default function PrecedentInventory() {
  const [openForm, setOpenForm] = useState(false);
  return (
    <div>
      <Filter />
      <AddMagazineButtonContainer>
        <AddMagazineButton
          onClick={() => {
            setOpenForm(true);
          }}
        >
          <span>+</span>
          <span>Add new magazine</span>
          <span lang="ar">أضف مجلة جديدة</span>
        </AddMagazineButton>
        {openForm ? (
          <>
            <RxCross1
              onClick={() => {
                setOpenForm(false);
              }}
              style={{ marginLeft: "auto", cursor: "pointer" }}
            />
          </>
        ) : null}
      </AddMagazineButtonContainer>
      {openForm ? (
        <AddMagazineFormContainer>
          <AddMagazine />
        </AddMagazineFormContainer>
      ) : (
        <>
          <NoteContainer>
            <Dot>
              <GoPrimitiveDot />
            </Dot>
            <Note>
              <div>
                This magazine contains more information than displayed and/or
                one or more accessible issues
              </div>
              <div lang="ar">
                تحتوي هذه المجلة على معلومات أكثر مما هو معروض و / أو واحد أو
                أكثر من الأعداد التي يمكن الوصول إليها
              </div>
            </Note>
          </NoteContainer>
          <PaginationContainer>
            Showing 10 out of 100 magazines{" "}
          </PaginationContainer>
          <CardsContainer>
            <MagazineCard />
            <MagazineCard />
            <MagazineCard />
          </CardsContainer>
          <ShowMoreContainer>
            <ShowMoreButton>Load more - تحميل المزيد</ShowMoreButton>
          </ShowMoreContainer>
        </>
      )}
    </div>
  );
}
