import React from "react";
import styled from "styled-components";

const CardTitle = styled.div`
  font-size: 26px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  font-weight: normal;
  font-style: normal;
  line-height: 35px;
  :hover {
    color: white;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  cursor: pointer;
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 20px;
`;

const DatapointContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Datapoint = styled.div`
  color: #000000;
  font-size: 18px;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  color: #6a6a6a;
`;

const Button = styled.button`
  height: 50px;
  bottom: 0%;
  transform: translateY(25px);
  background: #bdc1be;
  border: 0.5px solid #6a6a6a;
  border-radius: 22px;
  font-size: 16px;
  cursor: pointer;
  ::before {
    ${(props) =>
      props.contains_more
        ? `position: absolute;
    transform: translate(150px, -50%);
    left: 0;
    top: 0;
    height: 5px;
    width: 5px;
    border: 5px solid #ffb399;
    border-radius: 50%;
    background: #ffb399;
    content: "";`
        : null}
  }
`;

const BuildingNumber = styled.div`
  font-size: 42px;
  color: #6a6a6a;
`;

const BuildingCode = styled.div`
  font-size: 42px;
`;

const CardLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Container = styled.div`
  margin-top: 50px;
  width: 49%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50px;
  display: none;
`;

const CardContainer = styled.div`
  box-sizing: border-box;
  border: 0.5px solid #000000;
  border-radius: 3px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 0;
  position: relative;
  &:hover ${Image} {
    display: block;
  }
`;

export default function MagazineCard() {
  return (
    <Container>
      <CardLabelContainer>
        <BuildingNumber>#94</BuildingNumber>
      </CardLabelContainer>
      <CardContainer>
        <Image src={require("./images.png")} />
        <CardTitle>
          <div>Al Me’mar Magazine (Egyptian Society of Architects)</div>
          <div style={{ textAlign: "right" }} lang="ar">
            مجلة المعمار (جمعية المهندسين المعماريين المصرية)
          </div>
        </CardTitle>
        <CardDetailsContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>PUBLISHING DURATION</Label>
              1986 — unknown
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>مدة النشر</Label>
              ١٩٨٦ — غير معروف
            </Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>TOTAL NUMBER OF ISSUES</Label>
              16
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>إجمالي عدد الإصدارات</Label>
              ١٦
            </Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>ISSUING FREQUENCY</Label>
              Periodical
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>تردد الإصدار</Label>
              دورية
            </Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>EDITOR(S) IN CHIEF</Label>
              Yasmine El Dorghamy
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>رئيس التحرير</Label>
              ياسمين الدرغامي
            </Datapoint>
          </DatapointContainer>
        </CardDetailsContainer>
        <Button contains_more>
          Add info - <span lang="ar">أضِف معلومات أخرى</span>
        </Button>
      </CardContainer>
    </Container>
  );
}
