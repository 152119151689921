import React, { useState } from "react";
import styled from "styled-components";
import Textbox from "../Textbox/Textbox";
import Select from "../Select/Select";
import InfoEntry from "./InfoEntry";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { issue_date_format } from "../../constants/issue_date_format";
import { building_title_of_info_unverified } from "../../constants/building_title_of_info_unverified";

const IsVerifiedContainer = styled.div`
  padding: 25px;
  background-color: #b3b7b4;
`;
const IsVerifiedContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 5px;
`;

const IsVerifiedButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  gap: 200px;
  @media screen and (max-width: 480px) {
    gap: 100px;
  }
`;

const ButtonSelect = styled.button`
  font-size: 18px;
  background-color: transparent;
  border: none;
  border-radius: 19px;
  display: flex;
  gap: 5px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    gap: 5px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const SourceTypeContainer = styled.div`
  padding: 25px;
`;

const SourceTypeTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 5px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const SeperatorContainer = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: center;
`;

const SeperatorText = styled.div`
  font-size: 12px;
  color: #6a6a6a;
  clear: both;

  white-space: nowrap;
`;

const Seperator = styled.hr`
  border: 0;
  clear: both;
  display: block;
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (max-width: 480px) {
    width: 45%;
    justify-content: center;
  }
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const InputContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const InputLabel = styled.label`
  font-size: 18px;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
`;

const InformationTypeContainer = styled.div`
  padding: 25px;
`;

const SaveButtonContainer = styled.div`
  padding: 20px;
`;

const SaveButton = styled.button`
  border: 0.5px solid #6a6a6a;
  border-radius: 22px;
  background-color: transparent;
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
`;

const AddInfoButton = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  box-sizing: border-box;
`;

const UnverifiedContainer = styled.div`
  padding: 25px;
`;

const UnverfiedDataEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 25px;
`;

const LargeTextbox = styled.textarea`
  width: 100%;
  border: 0.5px solid #000000;
  border-radius: 3px;
  background-color: transparent;
  outline: none;
  padding: 10px;
  font-family: "TWK Lausanne";
  font-size: 16px;
  cursor: default;
  box-sizing: border-box;
`;

function BuildingAddInfoForm() {
  const [isVerifiedInfo, setIsVerifiedInfo] = useState(false);
  const [sourceType, setSourceType] = useState("magazine");
  const [infoType, setInfoType] = useState("text");
  const [numberOfEntries, setNumberOfEntries] = useState(1);

  const schema = yup
    .object({
      general_description: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const options = [
    { label: "test1", value: 1 },
    { label: "test2", value: 2 },
  ];

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IsVerifiedContainer>
        <IsVerifiedContainerTitle>
          <div>
            Do you have a reference source to verify the information you want to
            add?
          </div>
          <div lang="ar">
            هل لديك مصدر مرجعي للتحقق من المعلومات التي تريد إضافتها؟
          </div>
        </IsVerifiedContainerTitle>
        <IsVerifiedButtonContainer>
          <ButtonSelect
            style={{
              backgroundColor: isVerifiedInfo === false ? "#D8D8D8" : null,
            }}
            onClick={() => {
              setIsVerifiedInfo(false);
            }}
          >
            <span>No</span>
            <span lang="ar">لا</span>
          </ButtonSelect>
          <ButtonSelect
            style={{
              backgroundColor: isVerifiedInfo === true ? "#D8D8D8" : null,
            }}
            onClick={() => {
              setIsVerifiedInfo(true);
            }}
          >
            <span>Yes</span>
            <span lang="ar">نعم</span>
          </ButtonSelect>
        </IsVerifiedButtonContainer>
      </IsVerifiedContainer>
      {isVerifiedInfo ? (
        <>
          <SourceTypeContainer>
            <SourceTypeTitle>
              <div>
                Choose from the following what suits best your reply. All fields
                are required*
              </div>
              <div lang="ar">
                اختر من بين ما يلي أفضل ما يناسب ردك. جميع الحقول مطلوبة لملء*
              </div>
            </SourceTypeTitle>
            <SeperatorContainer>
              <SeperatorText>TYPE OF SOURCE</SeperatorText>
              <Seperator />
              <SeperatorText lang="ar">نوع المصدر</SeperatorText>
            </SeperatorContainer>
            <ButtonContainer>
              <ButtonSelect
                style={{
                  backgroundColor: sourceType === "magazine" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setSourceType("magazine");
                }}
              >
                <span>Magazine</span>
                <span lang="ar">مجلة</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor: sourceType === "book" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setSourceType("book");
                }}
              >
                <span>Book</span>
                <span lang="ar">كتاب</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor:
                    sourceType === "newspaper" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setSourceType("newspaper");
                }}
              >
                <span>Newspaper</span>
                <span lang="ar">جريدة</span>
              </ButtonSelect>
            </ButtonContainer>
            <InputRow>
              <InputContainer>
                <InputLabel>
                  <span>Name of source</span>
                  <span lang="ar">اسم المصدر</span>
                </InputLabel>
                <Textbox
                  placeholder={"Write name of source"}
                  {...register("source_name")}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>
                  <span>Issue number</span>
                  <span lang="ar">رقم الإصدار</span>
                </InputLabel>
                <Textbox
                  placeholder={"Write issue number"}
                  {...register("issue_number")}
                />
              </InputContainer>
            </InputRow>
            <InputRow>
              <InputContainer>
                <InputLabel>
                  <span>Issuing date format</span>
                  <span lang="ar">صيغة تاريخ الإصدار</span>
                </InputLabel>
                <Controller
                  name="issue_date_format"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={issue_date_format}
                      placeholder={"Choose issuing date format"}
                    />
                  )}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>
                  <span>Issuing date</span>
                  <span lang="ar">تاريخ الإصدار</span>
                </InputLabel>
                <Textbox
                  placeholder={"Write issuing date"}
                  {...register("issuing_date")}
                />
              </InputContainer>
            </InputRow>
          </SourceTypeContainer>
          <InformationTypeContainer>
            <SeperatorContainer>
              <SeperatorText>TYPE OF INFORMATION</SeperatorText>
              <Seperator />
              <SeperatorText lang="ar">نوع المعلومة</SeperatorText>
            </SeperatorContainer>
            <ButtonContainer>
              <ButtonSelect
                style={{
                  backgroundColor: infoType === "text" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setInfoType("text");
                }}
              >
                <span>Text</span>
                <span lang="ar">نص</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor:
                    infoType === "visual material" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setInfoType("visual material");
                }}
              >
                <span>Visual material</span>
                <span lang="ar">مادة بصرية</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor: infoType === "both" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setInfoType("both");
                }}
              >
                <span>Both</span>
                <span lang="ar">كلاهما</span>
              </ButtonSelect>
            </ButtonContainer>
            {[...Array(numberOfEntries - 1)].map((e, i) => (
              <InfoEntry register={register} key={i} />
            ))}
            <InfoEntry />
            <AddInfoButton
              onClick={() => {
                setNumberOfEntries(numberOfEntries + 1);
              }}
            >
              + add another piece of info
            </AddInfoButton>
          </InformationTypeContainer>
        </>
      ) : (
        <UnverifiedContainer>
          <SourceTypeTitle>
            <div>
              The added information you leave will be shared publicly and others
              will be able to upvote and/or comment.
            </div>
            <div lang="ar">
              ستتم مشاركة المعلومات المضافة التي تتركها علنًا وسيتمكن الآخرون من
              التصويت و / أو التعليق.
            </div>
          </SourceTypeTitle>
          <SeperatorContainer>
            <SeperatorText>TYPE OF INFORMATION</SeperatorText>
            <Seperator />
            <SeperatorText lang="ar">عنوان المعلومة</SeperatorText>
          </SeperatorContainer>
          <UnverfiedDataEntryContainer>
            <div>
              <InputLabel>
                <span>Title of info</span>
                <span lang="ar">رقم الإصدار</span>
              </InputLabel>
              <Controller
                name="title_of_info"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={building_title_of_info_unverified}
                    placeholder={"Choose info title"}
                  />
                )}
              />
            </div>
            <div>
              <InputLabel>
                <span>General description</span>
                <span lang="ar">وصف عام</span>
              </InputLabel>
              <LargeTextbox
                {...register("general_description")}
                placeholder="Character limit is 50"
                rows="10"
                maxLength="50"
              />
            </div>
            <div>
              <InputLabel>
                <span>How did you find out about this information?</span>
                <span lang="ar">كيف وجدت هذه المعلومات؟</span>
              </InputLabel>
              <LargeTextbox
                placeholder="Character limit is 50"
                rows="10"
                maxLength="50"
                {...register("info_source")}
              />
            </div>
          </UnverfiedDataEntryContainer>
        </UnverifiedContainer>
      )}
      <SaveButtonContainer>
        <SaveButton type={"submit"}>
          <div>Save</div>
          <div>-</div>
          <div lang="ar">حفظ</div>
        </SaveButton>
      </SaveButtonContainer>
    </form>
  );
}

export default BuildingAddInfoForm;
