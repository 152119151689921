import React from "react";
import BuildingCard from "../components/BuildingCard/BuildingCard";
import Filter from "../components/BuildingsFilter/Filter";
import styled from "styled-components";

const CardsContainer = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PaginationContainer = styled.div`
  padding-left: 80px;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
  }
  color: #787878;
  font-size: 16px;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 140px;
`;
const ShowMoreButton = styled.button`
  background: #bdc1be;
  border: 0.5px solid #6a6a6a;
  border-radius: 22px;
  font-size: 16px;
  width: 580px;
  padding: 10px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;

export default function BuildingInventory() {
  return (
    <div>
      <Filter />
      <PaginationContainer>
        Showing 10 out of 100 buildings{" "}
      </PaginationContainer>
      <CardsContainer>
        <BuildingCard />
        <BuildingCard />
        <BuildingCard />
      </CardsContainer>
      <ShowMoreContainer>
        <ShowMoreButton>Load more - تحميل المزيد</ShowMoreButton>
      </ShowMoreContainer>
    </div>
  );
}
