import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  box-sizing: border-box;
  border: 0.5px solid #000000;
  border-radius: 3px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 0;
`;

const CardTitle = styled.div`
  font-size: 26px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  font-weight: normal;
  font-style: normal;
  :hover {
    color: white;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 20px;
`;

const DatapointContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Datapoint = styled.div`
  color: #000000;
  font-size: 18px;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  color: #6a6a6a;
`;

const Button = styled.button`
  height: 50px;
  bottom: 0%;
  transform: translateY(25px);
  background: #bdc1be;
  border: 0.5px solid #6a6a6a;
  border-radius: 22px;
  font-size: 16px;
  cursor: pointer;
`;

const BuildingNumber = styled.div`
  font-size: 42px;
  color: #6a6a6a;
`;

const BuildingCode = styled.div`
  font-size: 42px;
`;

const CardLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Container = styled.div`
  margin-top: 50px;
  width: 49%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Location = styled.a`
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
`;

export default function BuildingCard() {
  return (
    <Container>
      <CardLabelContainer>
        <BuildingNumber>#94</BuildingNumber>
        <BuildingCode>WEILI-03</BuildingCode>
      </CardLabelContainer>
      <CardContainer>
        <CardTitle>
          <div>Ministry of Electricity and Energy</div>
          <div style={{ textAlign: "right" }} lang="ar">
            وزارة الكهرباء و الطاقة المتجددة
          </div>
        </CardTitle>
        <CardDetailsContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>3D Model</Label>
              N/A
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>نموذج ثلاثي الأبعاد</Label>
              لا يوجد
            </Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>Location</Label>
              <Location>
                8 Ramses, El Qobba Bridge, El Weili, Cairo Governorate
              </Location>
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>الموقع</Label>
              <Location target="_blank" href="google.com">
                ٨ رمسيس، كوبري القبة، الوايلي، محافظة القاهرة
              </Location>
            </Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>Architect</Label>
              The Arab Bureau For Design And Engineering Consultancy
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>المعماري</Label>
              المكتب العربي للتصميمات و الاستشارات الهندسية
            </Datapoint>
          </DatapointContainer>
          <DatapointContainer>
            <Datapoint>
              <Label>Date of Construction</Label>
              1983
            </Datapoint>
            <Datapoint lang="ar" style={{ textAlign: "right" }}>
              <Label>تاريخ البناء</Label>
              ١٩٨٣
            </Datapoint>
          </DatapointContainer>
        </CardDetailsContainer>
        <Button>
          Add info - <span lang="ar">أضِف معلومات أخرى</span>
        </Button>
      </CardContainer>
    </Container>
  );
}
