import React, { useState } from "react";
import styled from "styled-components";
import Textbox from "../Textbox/Textbox";
import Select from "../Select/Select";
import InfoEntry from "./InfoEntry";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { issue_date_format } from "../../constants/issue_date_format";
import { building_title_of_info_unverified } from "../../constants/building_title_of_info_unverified";
import IssueMedium from "../../enums/issueMediumEnum";

const IsVerifiedContainer = styled.div`
  padding: 25px;
  background-color: #b3b7b4;
`;
const IsVerifiedContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 5px;
`;

const IsVerifiedButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  gap: 200px;
  @media screen and (max-width: 480px) {
    gap: 100px;
  }
`;

const ButtonSelect = styled.button`
  font-size: 18px;
  background-color: transparent;
  border: none;
  border-radius: 19px;
  display: flex;
  gap: 5px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    gap: 5px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const SourceTypeContainer = styled.div`
  padding: 25px;
`;

const SourceTypeTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 5px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const SeperatorContainer = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: center;
`;

const SeperatorText = styled.div`
  font-size: 12px;
  color: #6a6a6a;
  clear: both;

  white-space: nowrap;
`;

const Seperator = styled.hr`
  border: 0;
  clear: both;
  display: block;
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (max-width: 480px) {
    width: 45%;
    justify-content: center;
  }
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const InputContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const InputLabel = styled.label`
  font-size: 18px;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
`;

const InformationTypeContainer = styled.div`
  padding: 25px;
`;

const SaveButtonContainer = styled.div`
  padding: 20px;
`;

const SaveButton = styled.button`
  border: 0.5px solid #6a6a6a;
  border-radius: 22px;
  background-color: transparent;
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
`;

const AddInfoButton = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  box-sizing: border-box;
`;

const UnverifiedContainer = styled.div`
  padding: 25px;
`;

const UnverfiedDataEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 25px;
`;

const LargeTextbox = styled.textarea`
  width: 100%;
  border: 0.5px solid #000000;
  border-radius: 3px;
  background-color: transparent;
  outline: none;
  padding: 10px;
  font-family: "TWK Lausanne";
  font-size: 16px;
  cursor: default;
  box-sizing: border-box;
`;

const Note = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 50px;
`;

const PublishingDuration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const FromToContainer = styled.div`
  font-size: 16px;
  display: flex;
  gap: 6px;
  margin-top: auto;
  margin-bottom: auto;
`;

const AvailableIssueButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 35px;
`;

function AddMagazine() {
  const [sourceType, setSourceType] = useState("magazine");
  const [infoType, setInfoType] = useState("text");
  const [numberOfEntries, setNumberOfEntries] = useState(1);
  const [availableIssues, setAvailableIssues] = useState(false);
  const [issueMedium, setIssueMedium] = useState(IssueMedium.ONLINE);

  const schema = yup
    .object({
      general_description: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const options = [
    { label: "test1", value: 1 },
    { label: "test2", value: 2 },
  ];

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Note>
        <div>
          Choose from the following what suits best your reply. All fields are
          required
        </div>
        <div>اختر من بين ما يلي أفضل ما يناسب ردك. جميع الحقول مطلوبة لملء</div>
      </Note>
      <SeperatorContainer>
        <SeperatorText>BASIC INFORMATION</SeperatorText>
        <Seperator />
        <SeperatorText lang="ar">معلومات أساسية</SeperatorText>
      </SeperatorContainer>
      <InputRow>
        <InputContainer>
          <InputLabel>
            <span>Title of magazine</span>
            <span lang="ar">عنوان المجلة</span>
          </InputLabel>
          <Textbox
            placeholder={"Write name of magazine"}
            {...register("magazine_title")}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            <span>Publishing date format</span>
            <span lang="ar">صيغة تاريخ النشر</span>
          </InputLabel>
          <Controller
            name="publishing_date_format"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={issue_date_format}
                placeholder={"Choose publishing date format"}
              />
            )}
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer style={{ width: "100%" }}>
          <InputLabel>
            <span>Publishing Duration</span>
            <span lang="ar">مدة النشر</span>
          </InputLabel>
          <PublishingDuration>
            <FromToContainer>
              <div>From</div>
              <div lang="ar">من</div>
            </FromToContainer>
            <Controller
              name="publishing_date_from"
              control={control}
              render={({ field }) => (
                <Select {...field} placeholder={"Choose year"} />
              )}
            />
            <FromToContainer>
              <div>To</div>
              <div lang="ar">إلي</div>
            </FromToContainer>
            <Controller
              name="publishing_date_to"
              control={control}
              render={({ field }) => (
                <Select {...field} placeholder={"Choose year"} />
              )}
            />
          </PublishingDuration>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputLabel>
            <span>Publishing month</span>
            <span lang="ar">شهر النشر</span>
          </InputLabel>
          <Controller
            name="publishing_month"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={issue_date_format}
                placeholder={"Choose publishing date format"}
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            <span>Total number of issues</span>
            <span lang="ar">العدد الإجمالي للإصدارات</span>
          </InputLabel>
          <Textbox
            placeholder={"Write total number of issues"}
            {...register("magazine_title")}
            pattern="[0-9]+"
          />
        </InputContainer>
      </InputRow>
      <div style={{ marginTop: "40px" }}>
        <InputLabel>
          <span>How did you know about this?</span>
          <span lang="ar">كيف حصلت علي هذه المعلومة؟</span>
        </InputLabel>
        <LargeTextbox
          {...register("general_description")}
          placeholder="Short description 150 words maximum"
          rows="10"
        />
      </div>
      <SeperatorContainer>
        <SeperatorText>AVAILABLE ISSUES</SeperatorText>
        <Seperator />
        <SeperatorText lang="ar">الإصدارات المتاحة</SeperatorText>
      </SeperatorContainer>
      <div style={{ marginTop: "40px" }}>
        <InputLabel>
          <span>Do you know of any available issues for this magazine?</span>
          <span lang="ar">هل تعرف أي أعداد متوفرة لهذه المجلة؟</span>
        </InputLabel>
        <AvailableIssueButtonContainer>
          <ButtonSelect
            style={{
              backgroundColor: availableIssues === true ? "#D8D8D8" : null,
            }}
            onClick={() => {
              setAvailableIssues(true);
            }}
          >
            <span>Yes</span>
            <span lang="ar">نعم</span>
          </ButtonSelect>
          <ButtonSelect
            style={{
              backgroundColor: availableIssues === false ? "#D8D8D8" : null,
            }}
            onClick={() => {
              setAvailableIssues(false);
            }}
          >
            <span>No</span>
            <span lang="ar">لا</span>
          </ButtonSelect>
        </AvailableIssueButtonContainer>
      </div>
      {availableIssues ? (
        <>
          <div style={{ marginTop: "40px" }}>
            <InputLabel>
              <span> In which medium did you find this issue?</span>
              <span lang="ar">في أي متوسط وجدت هذا الإصدار؟</span>
            </InputLabel>
            <AvailableIssueButtonContainer>
              <ButtonSelect
                style={{
                  backgroundColor:
                    issueMedium === IssueMedium.IN_PRINT ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setIssueMedium(IssueMedium.IN_PRINT);
                }}
              >
                <span>In print</span>
                <span lang="ar">مطبوعة</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor:
                    issueMedium === IssueMedium.ONLINE ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setIssueMedium(IssueMedium.ONLINE);
                }}
              >
                <span>Online</span>
                <span lang="ar">علي الإنترنت</span>
              </ButtonSelect>
            </AvailableIssueButtonContainer>
          </div>
          <SourceTypeContainer>
            <SourceTypeTitle>
              <div>
                Choose from the following what suits best your reply. All fields
                are required*
              </div>
              <div lang="ar">
                اختر من بين ما يلي أفضل ما يناسب ردك. جميع الحقول مطلوبة لملء*
              </div>
            </SourceTypeTitle>
            <SeperatorContainer>
              <SeperatorText>TYPE OF SOURCE</SeperatorText>
              <Seperator />
              <SeperatorText lang="ar">نوع المصدر</SeperatorText>
            </SeperatorContainer>
            <ButtonContainer>
              <ButtonSelect
                style={{
                  backgroundColor: sourceType === "magazine" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setSourceType("magazine");
                }}
              >
                <span>Magazine</span>
                <span lang="ar">مجلة</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor: sourceType === "book" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setSourceType("book");
                }}
              >
                <span>Book</span>
                <span lang="ar">كتاب</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor:
                    sourceType === "newspaper" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setSourceType("newspaper");
                }}
              >
                <span>Newspaper</span>
                <span lang="ar">جريدة</span>
              </ButtonSelect>
            </ButtonContainer>
            <InputRow>
              <InputContainer>
                <InputLabel>
                  <span>Name of source</span>
                  <span lang="ar">اسم المصدر</span>
                </InputLabel>
                <Textbox
                  placeholder={"Write name of source"}
                  {...register("source_name")}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>
                  <span>Issue number</span>
                  <span lang="ar">رقم الإصدار</span>
                </InputLabel>
                <Textbox
                  placeholder={"Write issue number"}
                  {...register("issue_number")}
                />
              </InputContainer>
            </InputRow>
            <InputRow>
              <InputContainer>
                <InputLabel>
                  <span>Issuing date format</span>
                  <span lang="ar">صيغة تاريخ الإصدار</span>
                </InputLabel>
                <Controller
                  name="issue_date_format"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={issue_date_format}
                      placeholder={"Choose issuing date format"}
                    />
                  )}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>
                  <span>Issuing date</span>
                  <span lang="ar">تاريخ الإصدار</span>
                </InputLabel>
                <Textbox
                  placeholder={"Write issuing date"}
                  {...register("issuing_date")}
                />
              </InputContainer>
            </InputRow>
          </SourceTypeContainer>
          <InformationTypeContainer>
            <SeperatorContainer>
              <SeperatorText>TYPE OF INFORMATION</SeperatorText>
              <Seperator />
              <SeperatorText lang="ar">نوع المعلومة</SeperatorText>
            </SeperatorContainer>
            <ButtonContainer>
              <ButtonSelect
                style={{
                  backgroundColor: infoType === "text" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setInfoType("text");
                }}
              >
                <span>Text</span>
                <span lang="ar">نص</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor:
                    infoType === "visual material" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setInfoType("visual material");
                }}
              >
                <span>Visual material</span>
                <span lang="ar">مادة بصرية</span>
              </ButtonSelect>
              <ButtonSelect
                style={{
                  backgroundColor: infoType === "both" ? "#D8D8D8" : null,
                }}
                onClick={() => {
                  setInfoType("both");
                }}
              >
                <span>Both</span>
                <span lang="ar">كلاهما</span>
              </ButtonSelect>
            </ButtonContainer>
            {[...Array(numberOfEntries - 1)].map((e, i) => (
              <InfoEntry register={register} key={i} />
            ))}
            <InfoEntry />
            <AddInfoButton
              onClick={() => {
                setNumberOfEntries(numberOfEntries + 1);
              }}
            >
              + add another piece of info
            </AddInfoButton>
          </InformationTypeContainer>
        </>
      ) : null}
      <SaveButtonContainer>
        <SaveButton type={"submit"}>
          <div>Save</div>
          <div>-</div>
          <div lang="ar">حفظ</div>
        </SaveButton>
      </SaveButtonContainer>
    </form>
  );
}

export default AddMagazine;
